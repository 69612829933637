import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      map: state => state.mapbox.map,
      mapFilter: state => state.mapbox.mapFilter
    }),
    ...mapGetters({
      sourceObjs: 'mapbox/sourceObjs',
      layerObjs: 'mapbox/layerObjs'
    })
  },
  methods: {
    ...mapMutations({
      setDataPendingState: 'mapbox/setDataPendingState',
      setSourceObjState: 'mapbox/updateSourceObj'
    }),
    ...mapActions({
      fetchSourceData: 'mapbox/socket_fetchSourceData'
    }),
    lazyLoadSource (layerId) {
      return new Promise((resolve, reject) => {
        // Find layer by 'layerId'
        const layer = this.map.getLayer(layerId)
        if (!layer) return reject(Error(`layer '${layerId}' not found`))

        // Get corresponding source id
        if (typeof layer.source !== 'string') return reject(Error(`layer '${layerId}' has a static source`))
        const sourceId = layer.source

        // Check if the source was correctly added to Mapbox
        const source = this.map.getSource(sourceId)
        if (!source) return reject(Error(`source '${sourceId}' not found`))

        // Check if the source is of type 'geojson' (only geojson sources can be lazy-loaded)
        if (source.type !== 'geojson') return resolve() // No need to lazy-load

        // Check if data has already been set, incidents we want to fetch every toggle
        const hasDataSet = source._data !== null
        if (hasDataSet && sourceId !== 'eprs-incidents') return resolve() // No need to lazy-load

        // All checks done - apply loading state
        this.setDataPendingState(true)

        // Find corresponding source obj
        const sourceObj = this.sourceObjs.find(s => s.properties.id === sourceId)

        // Fetch data
        this.fetchSourceData(sourceObj)
          .then((dataObj) => {
            if (!dataObj) {
              console.warn(`No data available for source '${sourceObj.properties.id}'`)
              this.setDataPendingState(false)
              resolve()
              return
            }
            /**
             * Set the data on the source.
             * Only geojson sources can be lazy loaded,
             * because all other layers get flagged with 'isMandatory'.
             * This makes it safe to use setData() here.
             */

            // Update source Object in state
            Object.assign(sourceObj, dataObj)
            this.setSourceObjState(sourceObj)
            const dataCopy = JSON.parse(JSON.stringify(dataObj))
            if (dataObj.data.features.length === 0) resolve() // TEMPORARY SOLUTION
            if (dataObj.data.features[0].properties && (dataObj.data.features[0].properties.Pipeline || dataObj.data.features[0].properties.pipeline_name)) { // check to see if sourceObj is an event source
              // you can't filter out clusters, so we'll have to do it manually and reset the data
              // mapFilter array to array of lines to filter out
              const linesToFilter = []
              for (let i = 1; i < this.mapFilter.length; i++) linesToFilter.push(this.mapFilter[i][2])

              // filter data
              if (dataObj.data.features[0].properties.Pipeline) {
                dataCopy.data.features = dataCopy.data.features.filter(f => !linesToFilter.includes(f.properties.Pipeline) && !linesToFilter.includes(f.properties.Pipeline.toUpperCase()))
              } else {
                dataCopy.data.features = dataCopy.data.features.filter(f => !linesToFilter.includes(f.properties.Pipeline) && !linesToFilter.includes(f.properties.pipeline_name.toUpperCase()))
              }
            }

            source.setData(dataCopy.data)

            this.setDataPendingState(false)
            resolve()
          })
          .catch((error) => {
            this.setDataPendingState(false)
            reject(error)
          })
      })
    }
  }
}
