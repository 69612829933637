<template>
  <div class="mapbox-gl-control risk-control"
       :style="[offsetStyle, posStyle]">
    <!-- Button -->
    <v-tooltip :left="attachLeft" :right="!attachLeft">
      <template #activator="{ on: tooltip }">
        <v-btn class="pa-1 ma-2"
               height="auto"
               min-width="0"
               :disabled="!allowedActive"
               :color="active ? 'primary darken-1' : 'white'"
               small
               v-on="{ ...tooltip }"
               @click="buttonClick">
          <v-icon>warning</v-icon>
        </v-btn>
      </template>
      <span>
        <slot name="tooltip">Toggle risks</slot>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import mapboxFiltersMixin from '@/utils/mixins/mapbox/mapboxFilters.mixin.js'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'pibot-mapbox-risk-toggle-basic',
  mixins: [positionStyleMixin, offsetStyleMixin, mapboxBasicsMixin, mapboxLazyMixin, mapboxFiltersMixin],
  data () {
    return {
      active: false
    }
  },
  computed: {
    ...mapState({
      selectedOptions: state => state.mapbox.selectedRisks,
      layersPending: state => state.mapbox.layersPending,
      riskDataLoaded: state => state.risks.loaded,
      mapFilter: state => state.mapbox.mapFilter
    }),
    ...mapGetters({
      riskOptions: 'mapbox/riskOptions',
      initialRisks: 'mapbox/initialRisks',
      layerObjs: 'mapbox/layerObjs'
    }),
    selectedNoOptions () {
      return this.selectedOptions.length === 0
    },
    allowedActive () {
      return this.riskOptions.length > 0
    }
  },
  methods: {
    ...mapMutations({
      setSelectedRisks: 'mapbox/setSelectedRisks'
    }),
    buttonClick () {
      this.active = !this.active
      for (const option of this.riskOptions) {
        if (!this.active) { // No need to lazy-load, continue as normal
          this.setLayerVisibility(option.value, this.active)
          continue
        }

        // Handle lazy-loading
        this.lazyLoadSource(option.value)
          .then(() => {
            this.setLayerVisibility(option.value, this.active)
          })
          .catch((error) => {
            console.error(error)
          })
      }
      if (this.active) this.setSelectedRisks(this.riskOptions.map(o => o.value))
      else this.setSelectedRisks([])
    }
  },
  watch: {
    mapFilter: function (mapFilter) {
      const riskFilter = ['all',
        ['!in', 'Name']
      ]
      for (let i = 1; i < mapFilter.length; i++) {
        riskFilter[1].push(mapFilter[i][2])
      }
      const riskLayers = this.layerObjs.filter(l => l.properties.category === 'risk').map(l => l.id)

      this.handleUnifiedFilter(riskLayers, riskFilter)
    }
  },
  created () {
    /**
     * Set initially 'visible' options as selected on init
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatchLayers = this.$watch('layersPending', (layersPending) => {
      if (!this.initialized && !layersPending) {
        this.setSelectedRisks(this.initialRisks)
        this.initialized = true
        this.active = !this.selectedNoOptions

        // Unwatch after init
        if (this.unwatchLayers) this.unwatchLayers()
        else console.error('unable to \'unwatch\' \'layersPending\'')
      }
    }, {
      deep: true
    })
  }
}
</script>
