import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      unifiedTurfFilters: state => JSON.parse(JSON.stringify(state.mapbox.unifiedTurfFilters))
    })
  },
  methods: {
    ...mapActions({
      setAndApplyTurfFilters: 'mapbox/setAndApplyTurfFilters'
    }),
    // arrayDiff (a, b) {
    //   return a.filter(x => !b.includes(x))
    // },
    async handleUnifiedFilter (layerIds, filter = null) {
      // Check parameters
      if (!Array.isArray(layerIds) || !layerIds.length) {
        console.error('layerIds is a required parameter')
        return
      }

      // Find the index of the Object to update
      const unifiedFilterIndex = this.unifiedTurfFilters.findIndex((fObj) => {
        return JSON.stringify(fObj.layerIds) === JSON.stringify(layerIds)
      })

      // Setup the new filter Object
      const newFilter = {
        layerIds,
        filter
      }

      // Concatenate existing filters
      // if (Array.isArray(filter)) {
      //   // Check if there are filters applied to some() layerIds for which we try to set new filters
      //   const appliedFilters = this.unifiedTurfFilters.filter(f => layerIds.some(x => f.layerIds.includes(x)) && !!f.filter)
      //   // if so, concatenate new filter with the already applied filters
      //   if (appliedFilters.length) {
      //     appliedFilters.forEach(({ filter }) => {
      //       newFilter.filter = [...new Set(filter.concat(newFilter.filter))]
      //     })
      //   }
      // }

      if (unifiedFilterIndex > -1) { // An Object to update was found, update it
        this.unifiedTurfFilters.splice(unifiedFilterIndex, 1, newFilter)
      } else this.unifiedTurfFilters.push(newFilter) // No Object to update was found, add a new one

      // Apply filters
      await this.setAndApplyTurfFilters(this.unifiedTurfFilters)
    }
  }
}
